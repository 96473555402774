import { useEffect } from "react";
import PublicLayout from "../layout/PublicLayout";
import { TextDictionary} from "../utils/TextDictionary";

const Home = () => {
    useEffect(() => {
        console.log("Home mounted");
    }, []);

    return (
        <PublicLayout>
            <div>
                <h1 className="text-xl font-medium">{TextDictionary.Home.Titolo}</h1>
            </div>
        </PublicLayout>
    );
};

export default Home;