import { useEffect, useState } from 'react';
import './App.css';
import { Navigate, Route, Routes, createBrowserRouter, RouterProvider } from 'react-router-dom';
import Home from './pages/Home';
import Courses from './pages/Admin/Courses/Courses';
import Users from './pages/Admin/Users/Users';
import Dashboard from './pages/Admin/Dashboard';
import Coupons from './pages/Admin/Coupons/Coupons';
import Quizzes from './pages/Admin/Quizzes/Quizzes';
import SingleUser from './pages/Admin/Users/SingleUser';
import SingleCourse from './pages/Admin/Courses/SingleCourse';
import SingleQuiz from './pages/Admin/Quizzes/SingleQuiz';
import Profile from './pages/Profile';
import SubscriptionInfo from './pages/SubscriptionInfo';
import Cart from './pages/Cart';
import Checkout from './pages/Checkout';
import CoursesList from './pages/Courses';
import UserCourses from './pages/Profile/UserCourses';
import UserCertificates from './pages/Profile/UserCertificates';
import HandleSubscriptions from './pages/Profile/HandleSubscriptions';
import axios from 'axios';
import { ApiConfig } from './config/Configuration';
import Resources from './pages/Admin/Resources/Resources';
import SingleResource from './pages/Admin/Resources/SingleResource';
import SingleCoupon from './pages/Admin/Coupons/SingleCoupon';

function App() {
  const [isAdmin, setIsAdmin] = useState(true);

  useEffect(() => {
    console.log("App mounted");
  }, []);

  const AdminGuard = ({ element: Component }: any) => { // Route privata per gli admin, se non hai i permessi ti reindirizza alla home
    return isAdmin ? <Component /> : <Navigate to="/" />;
  };

  // creo le routes
  const routes = createBrowserRouter([
    { path: "*", element: <Navigate to="/" /> },
    { path: "/", element: <Home /> },
    {
      path: "/profile/*", element: <Profile />, children: [
        { path: "", element: <Navigate to="/profile/courses" /> },
        { path: "courses", element: <UserCourses /> },
        { path: "certificates", element: <UserCertificates /> },
        { path: "subscriptions", element: <HandleSubscriptions /> }
      ]
    },
    { path: "/subscription-info", element: <SubscriptionInfo /> },
    { path: "/cart", element: <Cart /> },
    { path: "/checkout", element: <Checkout /> },
    {
      path: "/courses-list",
      element: <CoursesList />,
      loader: async () => {
        const response = await axios.get(`${ApiConfig.ROOT_URL}/courses`); // Chiamo l'API per ottenere tutti i corsi prima di renderizzare la pagina
        return response.data; // Ritorno i dati dei corsi
      },
    },
    { path: "/admin", element: <AdminGuard element={Dashboard} /> },
    { path: "/admin/dashboard", element: <AdminGuard element={Dashboard} /> },
    { path: "/admin/users", element: <AdminGuard element={Users} />, },
    {
      path: "/admin/users/:id",
      element: <AdminGuard element={SingleUser} />,
      loader: async (params: any) => {
        console.log('params', params);
        const response = await axios.get(`${ApiConfig.ROOT_URL}/users/${params.params.id}`); // Chiamo l'API per ottenere l'utente prima di renderizzare la pagina
        return response.data; // Ritorno i dati dell'utente
      }
    },
    { path: "/admin/users/new", element: <AdminGuard element={SingleUser} /> },
    { path: "/admin/courses", element: <AdminGuard element={Courses} /> },
    { path: "/admin/courses/:id", element: <AdminGuard element={SingleCourse} /> },
    { path: "/admin/quizzes", element: <AdminGuard element={Quizzes} /> },
    {
      path: "/admin/quizzes/:id",
      element: <AdminGuard element={SingleQuiz} />,
      loader: async (params: any) => {
        console.log('params', params);
        const response = await axios.get(`${ApiConfig.ROOT_URL}/quizzes/${params.params.id}`); // Chiamo l'API per ottenere il quiz prima di renderizzare la pagina
        return response.data; // Ritorno i dati del quiz
      }
    },
    { path: "/admin/resources", element: <AdminGuard element={Resources} /> },
    { path: "/admin/resources/:id", element: <AdminGuard element={SingleResource} /> },
    { path: "/admin/coupons", element: <AdminGuard element={Coupons} /> },
    {
      path: "/admin/coupons/:id",
      element: <AdminGuard element={SingleCoupon} />,
      loader: async (params: any) => {
        console.log('params', params);
        const response = await axios.get(`${ApiConfig.ROOT_URL}/coupons/${params.params.id}`); // Chiamo l'API per ottenere il coupon prima di renderizzare la pagina
        return response.data; // Ritorno i dati del coupon
      }
    }
  ]);

  return (
    <RouterProvider router={routes} />
  )
}

export default App;