import { useEffect } from "react";
import AdminLayout from "../../../layout/AdminLayout";
import { Link } from "react-router-dom";
import { TableComponent } from "../../../components/TableComponent";
import { TextDictionary } from "../../../utils/TextDictionary";


const Resources = () => {
    useEffect(() => {
        console.log("Resources mounted");
    }, []);
    
    return (
        <AdminLayout>
          <div className="relative flex flex-col w-full h-full">
                <div className="flex flex-col w-full mb-8">
                    <h1 className="text-3xl font-semibold">{TextDictionary.Admin.Risorse.Titolo}</h1>
                </div>
                <TableComponent
                    endpoint="/resources"
                />
            </div>
        </AdminLayout>
    );
};

export default Resources;