export const TextDictionary = {
    "Home": {
        "Titolo": "Home",
    },
    "Profilo": {
        "Titolo": "Profilo personale",
        "MieiCorsi": "I miei corsi",
        "Certificati": "Certificati",
        "GestioneAbbonamenti": "Gestione abbonamenti",
    },
    "InfoAbbonamento": {
        "Titolo": "Info abbonamento",
    },
    "ListaCorsi": {
        "Titolo": "Scopri tutti \ni corsi",
        "Sottotitolo": "Corsi di formazione online \nriconosciuti a livello europeo!",
        "CreaAccountGratis": "Crea il tuo account gratis",
        "ScopriCorso": "Scopri il corso",
    },
    "Checkout": {
        "Titolo": "Checkout",
    },
    "Carrello": {
        "Titolo": "Carrello",
    },
    "CorsiUtente": {
        "Titolo": "I miei corsi",
    },
    "CertificatiUtente": {
        "Titolo": "I miei certificati",
    },
    "GestioneAbbonamenti": {
        "Titolo": "Gestione abbonamenti",
    },
    "TabelleDinamiche": {
        "Errore": "Attenzione!",
        "ErroreMessaggio": "Errore nel caricamento dei dati",
        "BottoneRiprova": "Riprova",
    },

    "Admin": {
        "Dropzone": {
            "Testo": "Trascina qui il file o clicca per selezionarlo",
        },


        "Tabelle": {
            "Utenti": {
                "Address": "Indirizzo",
                "City": "Città",
                "Company": "Azienda",
                "Country": "Paese",
                "Email": "Email",
                "Enabled": "Abilitato",
                "ID": "ID",
                "IsEmployee": "Dipendente",
                "IsSubscribed": "Abbonato",
                "Language": "Lingua",
                "LastLogin": "Ultimo accesso",
                "Name": "Nome",
                "Phone": "Telefono",
                "Province": "Provincia",
                "Sdi": "Sdi",
                "Surname": "Cognome",
                "TaxCode": "Codice fiscale",
                "URLImage": "Immagine",
                "VatCode": "Partita IVA",
                "ZIP": "CAP"
            },
            "Video": {
                "ID": "ID",
                "Title": "Titolo",
                "Description": "Descrizione",
                "URLVideo": "URL Video",
                "IDCourse": "ID Corso"
            }
        },

        "Dashboard": {
            "CollegamentoUtenti": "Utenti",
            "CollegamentoCorsi": "Corsi",
            "CollegamentoRisorse": "Risorse",
            "CollegamentoQuiz": "Quiz",
            "CollegamentoCoupons": "Coupons",
        },
        "Utenti": {
            "Titolo": "Utenti",
            "NuovoUtente": "Nuovo utente",
            "ModificaUtente": "Modifica utente",
            "BottoneTornaIndietro": "Torna indietro",
            "BottoneAnnula": "Annulla",
            "BottoneSalva": "Salva",

            "CampoNome": "Nome",
            "CampoCognome": "Cognome",
            "CampoAzienda": "Azienda",
            "CampoEmail": "Email",
            "CampoTelefono": "Telefono",
            "CampoProvincia": "Provincia",
        },
        "Risorse": {
            "Titolo": "Risorse"
        },
        "Quiz": {
            "Titolo": "Quiz",
            "NuovoQuiz": "Nuovo quiz",
            "ModificaQuiz": "Modifica quiz",
            
            "CampoNome": "Nome",
            "CampoDescrizione": "Descrizione",
            "CampoFile": "Seleziona file",
            "CampoPunteggioMinimo": "Punteggio minimo",
            "PlaceholderPunteggioMassimo": "Punteggio massimo",

            "VideoAssociati": "Video associati",
            "AggiungiVideo": "Aggiungi video",
            "NessunVideo": "Nessun video collegato al quiz",
        },
        "Corsi": {
            "Titolo": "Corsi"
        },
        "Coupons": {
            "Titolo": "Coupons",
            "NuovoCoupon": "Nuovo coupon",
            "ModificaCoupon": "Modifica coupon",
            "BottoneTornaIndietro": "Torna indietro",

            "CampoNome": "Codice sconto",
            "CampoDescrizione": "Descrizione",
            "CampoImporto": "Importo del codice promozionale",
            "BottoneGenera": "Genera codice promozionale",
            "TipologiaPercentuale": "Percentuale",
            "TipologiaFisso": "Fisso",
            "UtentiAssociati": "Utenti associati",
            "AggiungiUtenti": "Aggiungi utenti",
            "NessunDato": "Coupon valido per tutti gli utenti",
            "NessunNuovoUtente": "Nessun nuovo utente da associare",
            "Autore": "Autore",
            "DataCreazione": "Data creazione",
            "DataModifica": "Ultima modifica",

            "BottoneSalva": "Salva",
            "BottoneAnnulla": "Annulla",

            "PlaceholderAutore": "Admin",
            "PlaceholderData": "gg/mm/aa",
        }
    },



    "Autenticazione": {
        "Login": {
            "Titolo": "Accedi",
        },
        "Registrazione": {
            "Titolo": "Registrati",
        },
        "RecuperoPassword": {
            "Titolo": "Recupero password",
        },
        "ResetPassword": {
            "Titolo": "Reset password",
        },
    }
}