import { useEffect } from "react";
import AdminLayout from "../../../layout/AdminLayout";
import { Link, useParams } from "react-router-dom";


const SingleCourse = () => {
    const { id } = useParams();
    
    return (
        <AdminLayout>
            <div>
                <h1 className="text-xl font-medium">SingleCourse {id}</h1>
                <Link to="/admin/courses">Torna a Courses</Link>
            </div>
        </AdminLayout>
    );
};

export default SingleCourse;