import { useEffect } from "react";
import AdminLayout from "../../../layout/AdminLayout";
import { Link, useLoaderData, useNavigate, useParams } from "react-router-dom";
import EmptyAvatar from "../../../assets/images/empty-avatar.png";
import { TextDictionary } from "../../../utils/TextDictionary";

const SingleUser = () => {
    const navigate = useNavigate();
    // Pesco dall'url l'id dell'utente
    const { id } = useParams();
    const user = useLoaderData() as any | null;

    useEffect(() => {
        console.log("SingleUser mounted", id);
        console.log("user", user);
    }, []);

    const handleSave = () => {
        console.log("Salva");
    }

    const goBack = () => {
        navigate('/admin/users');
    }

    return (
        <AdminLayout>
            <div className="max-w-7xl mx-auto px-4 py-2 relative overflow-hidden">
                <div className="flex flex-row justify-between w-full mb-12">
                    <h1 className="text-2xl font-semibold">{id ? TextDictionary.Admin.Utenti.ModificaUtente : TextDictionary.Admin.Utenti.NuovoUtente}</h1>
                    <button className="text-black underline py-2 rounded w-40 flex justify-end">
                        <Link to="/admin/users">{TextDictionary.Admin.Utenti.BottoneTornaIndietro}</Link>
                    </button>
                </div>

                {/* Dati anagrafici */}
                <div className="grid grid-cols-3 border border-black border-opacity-10 mb-8">
                    <div className="col-span-1 border-r border-black border-opacity-10 flex justify-center">
                        <img src={EmptyAvatar} alt="Empty avatar illustration" className="w-[150px] h-[150px] mx-auto" />
                    </div>
                    <div className="col-span-2">
                        <div className="flex flex-col border-b border-black border-opacity-10 pb-4">
                            <div className="grid grid-cols-2 gap-4 p-4">
                                <div className="flex flex-row gap-8">
                                    <label htmlFor="name" className="font-medium text-sm">{TextDictionary.Admin.Utenti.CampoNome}</label>
                                    <input type="text" id="name" className="border border-black border-opacity-10" defaultValue={id ? user.Name : ''} />
                                </div>
                                <div className="flex flex-row gap-8">
                                    <label htmlFor="surname" className="font-medium text-sm">{TextDictionary.Admin.Utenti.CampoCognome}</label>
                                    <input type="text" id="surname" className="border border-black border-opacity-10" defaultValue={id ? user.Surname : ''} />
                                </div>
                            </div>
                            <div className="flex flex-row gap-8 p-4">
                                <label htmlFor="company" className="font-medium text-sm">{TextDictionary.Admin.Utenti.CampoAzienda}</label>
                                <input id="company" className="border border-black border-opacity-10" defaultValue={id ? user.Company : ''} />
                            </div>
                        </div>
                        <div className="flex flex-col gap-4 pt-4">
                            <div className="flex flex-row gap-8 p-4">
                                <label htmlFor="email" className="font-medium text-sm">{TextDictionary.Admin.Utenti.CampoEmail}</label>
                                <input type="text" id="email" className="border border-black border-opacity-10" defaultValue={id ? user.Email : ''}
                                    disabled={id ? true : false}
                                    style={{ backgroundColor: id ? '#f9f9f9' : 'white' }}
                                />
                            </div>
                            <div className="flex flex-row gap-8 p-4">
                                <label htmlFor="phone" className="font-medium text-sm">{TextDictionary.Admin.Utenti.CampoTelefono}</label>
                                <input type="text" id="phone" className="border border-black border-opacity-10" defaultValue={id ? user.Phone : ''} />
                            </div>
                            <div className="flex flex-row gap-8 p-4">
                                <label htmlFor="province" className="font-medium text-sm">{TextDictionary.Admin.Utenti.CampoProvincia}</label>
                                <input type="text" id="province" className="border border-black border-opacity-10" defaultValue={id ? user.Province : ''} />
                            </div>
                        </div>
                    </div>
                </div>

                {/* Abbonamenti */}
                <div className="h-48 border border-black border-opacity-10">

                </div>

                {/* Coupons */}
                {/* <div className="h-48 border border-black border-opacity-10">

                </div> */}

                {/* Footer fixed */}
                <div className="w-full bg-primary p-4 flex justify-end">
                    <button className=" text-white underline py-2 px-4 rounded w-40" onClick={goBack}>
                        {TextDictionary.Admin.Utenti.BottoneAnnula}
                    </button>
                    <button className="bg-white text-primary font-medium py-2 px-4 rounded w-40" onClick={handleSave}>
                        {TextDictionary.Admin.Utenti.BottoneSalva}
                    </button>
                </div>
            </div>
        </AdminLayout>
    );
};

export default SingleUser;