import { ReactNode, useEffect } from "react";
import PublicLayout from "../layout/PublicLayout";
import { Outlet, NavLink } from "react-router-dom";
import { TextDictionary } from "../utils/TextDictionary";

const Profile = () => {
    useEffect(() => {
        console.log("Profile mounted");
    }, []);

    return (
        <PublicLayout>
            <div className="flex flex-row h-full bg-white">
                <aside className="flex w-60 h-full flex-col overflow-y-hidden border-r border-black border-opacity-10">
                    <h2 className="text-primary text-lg font-medium text-center py-4">{TextDictionary.Profilo.Titolo}</h2>
                    <div className="border-t border-black border-opacity-10 mb-2"></div>
                    <ul className="flex flex-col gap-2.5">
                        <li>
                            <NavLink
                                to="courses"
                                className="relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-black"
                                style={({ isActive, isPending }) => {
                                    return {
                                        textDecoration: isActive || isPending ? "underline" : "none",
                                    };
                                }}
                            >
                                {TextDictionary.Profilo.MieiCorsi}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="certificates"
                                className="relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-black"
                                style={({ isActive, isPending }) => {
                                    return {
                                        textDecoration: isActive || isPending ? "underline" : "none",
                                    };
                                }}
                            >
                                {TextDictionary.Profilo.Certificati}
                            </NavLink>
                        </li>
                        <li>
                            <NavLink
                                to="subscriptions"
                                className="relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-black"
                                style={({ isActive, isPending }) => {
                                    return {
                                        textDecoration: isActive || isPending ? "underline" : "none",
                                    };
                                }}
                            >
                                {TextDictionary.Profilo.GestioneAbbonamenti}
                            </NavLink>
                        </li>
                    </ul>
                </aside>
                <main className="grow px-4 py-4">
                    {/* Qui verranno renderizzati i componenti figli */}
                    <Outlet />
                </main>
            </div>
        </PublicLayout>
    );
};

export default Profile;
