import { TextDictionary } from "../utils/TextDictionary"

interface FloatingAdminCardProps {
    onSave: () => void;
    onDiscard: () => void;
}

export const FloatingBottomToolbar = ({onSave, onDiscard}: FloatingAdminCardProps) => {
    return (
        // <div className="flex flex-col gap-4 w-72 relative">
        //     <div className="flex flex-col gap-2 w-full shadow-lg p-4 sticky top-[148px]">
        //         <div className="flex flex-row justify-between px-4">
        //             <span className="text-sm">{TextDictionary.Admin.Coupons.Autore}</span>
        //             <span className="text-sm font-semibold">{TextDictionary.Admin.Coupons.PlaceholderAutore}</span>
        //         </div>
        //         <div className="flex flex-row justify-between px-4">
        //             <span className="text-sm ">{TextDictionary.Admin.Coupons.DataCreazione}</span>
        //             <span className="text-sm font-semibold">{TextDictionary.Admin.Coupons.PlaceholderData}</span>
        //         </div>
        //         <div className="flex flex-row justify-between px-4 mb-2">
        //             <span className="text-sm ">{TextDictionary.Admin.Coupons.DataModifica}</span>
        //             <span className="text-sm font-semibold">{TextDictionary.Admin.Coupons.PlaceholderData}</span>
        //         </div>
        //         <div className="border-t border-black border-opacity-10"></div>

        //         <div className="flex flex-row justify-between gap-8 p-4">
        //             <button className="bg-[#2d8a2b] text-white px-4 py-1 w-full flex-1 hover:bg-transparent hover:text-[#2d8a2b] border border-[#2d8a2b]" onClick={onSave}>{TextDictionary.Admin.Coupons.BottoneSalva}</button>
        //             <button className=" text-black px-4 py-1 w-full flex-1 underline bg-[#F8F8F8] border-gray-200 border" onClick={onDiscard}>{TextDictionary.Admin.Coupons.BottoneAnnulla}</button>
        //         </div>
        //     </div>
        // </div>


        <div className="flex flex-row gap-4 fixed bottom-0 right-0 bg-white p-4 shadow-lg">
            <button className="bg-[#2d8a2b] text-white px-4 py-1 hover:bg-transparent hover:text-[#2d8a2b] border border-[#2d8a2b]" onClick={onSave}>{TextDictionary.Admin.Coupons.BottoneSalva}</button>
            <button className=" bg-red-600 text-white px-4 py-1 hover:bg-transparent hover:text-red-600 border border-red-600" onClick={onDiscard}>{TextDictionary.Admin.Coupons.BottoneAnnulla}</button>
        </div>
    )
}