import { useNavigate } from "react-router-dom";
import { Course } from "../models/course";
import { PrimaryButton } from "./PrimaryButton";
import { TextDictionary } from "../utils/TextDictionary";

interface CourseCardProps {
    course: Course;
    inverse?: boolean;
}

export const CourseCard = ({ course, inverse }: CourseCardProps) => {
    const navigate = useNavigate();

    return (
        <div className={`flex flex-col flex-1 pb-8 shadow-md rounded-3xl mr-8 ${inverse ? 'bg-primary' : 'bg-white'}`}>
            <div className="flex flex-col h-48">
                <img src="https://via.placeholder.com/150" alt="course" className="object-cover w-full h-full rounded-t-3xl" />
            </div>
            <div className="flex flex-col h-full px-8 pt-8">
                <h2 className={`text-3xl font-medium text-primary pb-2 ${inverse ? 'text-white' : 'text-primary'}`}>{course.Title}</h2>
                {/* Aggiungo un divisorio */}
                <div className={`border-t my-2 ${inverse ? 'border-customYellow' : 'border-gray-200'}`}></div>

                <p className={`text-sm font-medium pb-2 ${inverse ? 'text-white' : 'text-primary'}`}>{course.Date.toString()}</p>
                <p className={`text-sm font-medium text-primary pb-4 ${inverse ? 'text-white' : 'text-primary'}`}>Durato: 2 ore</p> {/* Qui ci andrà la durata del corso pescata dal json */}

                <span className={`text-sm text-gray-500 ${inverse ? 'text-white' : 'text-gray-500'}`}>{course.ShortDescription}</span>

                <PrimaryButton text={TextDictionary.ListaCorsi.ScopriCorso} onClick={() => {
                    console.log(`Clicked on course ${course.ID}`);
                    navigate(`/course/${course.ID}`);
                }} />
            </div>
        </div>
    )
}