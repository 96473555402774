import { useEffect } from "react";
import AdminLayout from "../../layout/AdminLayout";
import { Link } from "react-router-dom";
import { TextDictionary } from "../../utils/TextDictionary";

const Dashboard = () => {
    useEffect(() => {
        console.log("Admin Dashboard mounted");
    }, []);

    const Card = (route: string, text: string) => {
        return (
            <Link to={route}>
                <div className="h-52 w-52 bg-white p-5 rounded-lg shadow-lg flex justify-center items-center flex-col gap-8">
                    <div className="h-12 w-12 bg-black rounded-full flex justify-center items-center" />
                    <h2 className="text-lg">{text}</h2>
                </div>
            </Link>
        );
    }

    return (
        <AdminLayout>
            <div className="flex justify-center"> 
                {/* Creo una griglia di 3 card e 2 righe dove ogni card avrà un icona e un testo che indicherà il nome della route a cui andare. al click sulla card si deve navigare alla route corrispondente */}
                <div className="flex flex-row gap-12">
                    {Card("/admin/users", TextDictionary.Admin.Dashboard.CollegamentoUtenti)}
                    {Card("/admin/courses", TextDictionary.Admin.Dashboard.CollegamentoCorsi)}
                    {Card("/admin/quizzes", TextDictionary.Admin.Dashboard.CollegamentoQuiz)}
                    {Card("/admin/resources", TextDictionary.Admin.Dashboard.CollegamentoRisorse)}
                    {Card("/admin/coupons", TextDictionary.Admin.Dashboard.CollegamentoCoupons)}
                </div>
            </div>
        </AdminLayout>
    );
};

export default Dashboard;