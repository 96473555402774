import { useEffect } from "react";
import AdminLayout from "../../../layout/AdminLayout";
import { Link, useParams } from "react-router-dom";

const SingleResource = () => {
    const { id } = useParams();
    
    return (
        <AdminLayout>
            <div>
                <h1 className="text-xl font-medium">SingleResource {id}</h1>
                <Link to="/admin/resources">Torna a Resources</Link>
            </div>
        </AdminLayout>
    );
};

export default SingleResource;