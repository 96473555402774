import { createGrid, GridApi, GridOptions, IDatasource, IGetRowsParams } from "ag-grid-community";
import { useEffect, useState } from "react";
import axios from "axios";
import { ApiConfig } from "../config/Configuration";
import { useNavigate } from "react-router-dom";
import { TextDictionary } from "../utils/TextDictionary";

interface TableComponentProps {
  endpoint: string;
}

interface HttpFormParams {
  page_number?: number;
  items_per_page?: number;

  order_by?: string;
  order_sort?: string;

  filters?: string;
  filter_values?: string;
}

export const TableComponent = (props: TableComponentProps) => {
  const navigate = useNavigate();
  const endPointUrl = ApiConfig.ROOT_URL + props.endpoint;

  const [columns, setColums] = useState<any[]>([]); // List of columns for the selected form type
  const [gridApi, setGridApi] = useState<GridApi | null>(null);
  const [dataLoading, setDataLoading] = useState<boolean>(true);

  useEffect(() => {
    if (props.endpoint)
      loadColums();
  }, [props.endpoint]);

  useEffect(() => {
    if (columns && columns.length > 0) {
      createTableInfinite();
    }
  }, [columns]);


  const loadColums = async () => {
    const response = await axios.get(endPointUrl, {
      params: {
        page_number: 1,
        items_per_page: 1
      }
    });

    if (response.data.length > 0) {
      const columns = getColumnListData(response.data[0]);
      setColums(columns);
    }
  }

  const getColumnListData = (row: any) => {
    const gridColumnList: { headerName: string, field: string }[] = [];
    // headerName e field sono uguali e sono le chiavi dell'oggetto row

    for (const key in row) {
      const field = key;
      if (!gridColumnList.find((column) => column.field === field)) {
        gridColumnList.push({ headerName: field, field: field });
      }
    }

    // Aggiungo la colonna delle azioni
    gridColumnList.push({ headerName: 'Azioni', field: 'ID' });

    const returnGridColumnsList = gridColumnList.map((column) => {
      if (column.headerName === 'Azioni') {
        return {
          headerName: '',
          field: column.field,
          // Nascondo il nome della colonna
          cellRenderer: (params: any) => {
            const fieldValue = params.value;
            // faccio in modo che il value della riga sia l'ID dell'elemento, in modo da poterlo passare alla funzione di modifica o cancellazione
            const handleEdit = (fieldValue: any, props: TableComponentProps) => {
              navigate('/admin' + props.endpoint + '/' + fieldValue);
            };

            const handleDelete = (fieldValue: any) => {
              console.log('Delete clicked for row ID:');
              console.log('params ID', fieldValue);
              // TODO: Implementare l'endpoint per la cancellazione
            };

            const container = document.createElement('div');
            container.className = 'flex items-center gap-2 w-full mt-1';

            const editButton = document.createElement('button');
            editButton.className = 'bg-primary text-white text-sm px-6 py-1 rounded-full hover:bg-transparent hover:text-primary border border-primary';
            editButton.innerText = 'Modifica';
            editButton.addEventListener('click', () => handleEdit(fieldValue, props));

            const deleteButton = document.createElement('button');
            deleteButton.className = 'bg-red-500 text-white text-sm px-6 py-1 rounded-full hover:bg-transparent hover:text-red-500 border border-red-500';
            deleteButton.innerText = 'X';
            deleteButton.addEventListener('click', () => handleDelete(fieldValue));

            container.appendChild(editButton);
            container.appendChild(deleteButton);

            return container;
          }
        }
      }
      return {
        headerName: column.headerName,
        field: column.field,
        sortable: true,
        filter: 'agTextColumnFilter',
        maxWidth: 300, // Larghezza massima della colonna
        filterParams: {
          defaultOption: 'contains',
          suppressAndOrCondition: true,
          filterOptions: ['contains']
        },
        resizable: true,
        cellRenderer: (params: any) => {
          if (params.value === null || params.value === undefined) {
            return '';
          }
          return `<span>${params.value}</span>`;
        },
        // Mostro una tooltip con il contenuto completo della cella
        tooltipShowDelay: 500,
        tooltipValueGetter: (params: any) => {
          return params.value;
        },
      };
    });

    return returnGridColumnsList;
  }

  const sizeToFit = () => {
    console.log('-------------------- sizeToFit gridApi', gridApi);
    setTimeout(() => {
      gridApi?.autoSizeAllColumns();
    }, 100)
  }

  useEffect(() => {
    if (dataLoading === false && gridApi) {
      console.log('-------------------- sizeToFit invoked');
      sizeToFit();
    }
  }, [dataLoading]);


  const createTableInfinite = () => {
    const gridOptions = {
      rowModelType: 'infinite',
      cacheBlockSize: 50, // Ogni blocco scaricato dal server contiene 50 righe
      pagination: true,
      paginationPageSize: 50,
      paginationPageSizeSelector: [50, 100, 200],
      paginationAutoPageSize: false,
      columnDefs: columns,
      enableCellTextSelection: true,
      singleClickEdit: true,
      autoSizeStrategy: {
        type: "fitCellContents",
        // skipHeader: true
      },
    } as GridOptions;

    const dataSource: IDatasource = {
      rowCount: undefined,
      getRows: (params: IGetRowsParams) => {
        setDataLoading(true);
        const sortModel = params.sortModel;
        const filterModel = params.filterModel;

        const chunkPaginationPageSize = 50;

        // Calcolo la pagina corrente in base ai parametri di infinite scrolling
        const pageNumber = Math.floor(params.startRow / chunkPaginationPageSize) + 1;

        const httpParams: HttpFormParams = {
          page_number: pageNumber,
          items_per_page: chunkPaginationPageSize
        }

        if (sortModel.length > 0) {
          httpParams['order_by'] = sortModel[0].colId;
          httpParams['order_sort'] = sortModel[0].sort;
        }

        const filterKeys = Object.keys(filterModel);
        if (filterKeys.length > 0) {
          httpParams['filters'] = filterKeys.map((key) => encodeURIComponent(key)).join(',');
          httpParams['filter_values'] = filterKeys.map((key) => encodeURIComponent(filterModel[key].filter)).join(',');
        }

        axios.get(endPointUrl, {
          params: httpParams
        }).then((response) => {
          if (response.data.length === 0) {
            setDataLoading(false);
            params.successCallback([], 0);
            return;
          }

          const rowsThisBlock: any[] = []
          response.data.forEach((element: any) => {
            const row: any = {};
            columns.forEach((column) => {
              row[column.field] = element[column.field];
            });
            rowsThisBlock.push(row);
          });


          const lastRow = response.data.length < chunkPaginationPageSize ? params.startRow + response.data.length : -1;
          // Pass the data to the grid
          setTimeout(() => {
            setDataLoading(false);
            params.successCallback(rowsThisBlock, lastRow);
          }, 0);
        }).catch((error: any) => {
          console.error("Error fetching rows", error);
          setDataLoading(false);
          params.failCallback();
        });
      },
    };

    const myGrid = document.querySelector("#myGrid") as HTMLElement;
    if (myGrid) {
      myGrid.innerHTML = "";
    }

    gridOptions.datasource = dataSource;

    const grid = createGrid(myGrid, gridOptions);
    setGridApi(grid);
  };


  if (!columns && !dataLoading) { // Se il caricamento dei dati fallisce, visualizza un messaggio di errore e un pulsante per riprovare
    return (
      <>
        <span className="block text-xl text-black font-semibold">{TextDictionary.TabelleDinamiche.Errore}</span>
        <span className="block text-md mb-5 text-black font-normal">{TextDictionary.TabelleDinamiche.ErroreMessaggio}</span>
        <button
          className="bg-primary text-white text-sm px-6 py-1 rounded-full hover:bg-transparent hover:text-primary border border-primary"
          onClick={() => loadColums()}
        >
          {TextDictionary.TabelleDinamiche.BottoneRiprova}
        </button>
      </>
    );
  }

  return (
    <div className="relative h-full w-full">
      <div id="myGrid" className="ag-theme-quartz h-full w-full" />
      {dataLoading && (
        <div className="absolute inset-0 bg-white bg-opacity-80 flex items-center justify-center">
          <div className="flex flex-col items-center">
            <div className="h-8 w-8 animate-spin rounded-full border-2 border-solid border-primary border-t-transparent"></div>
          </div>
        </div>
      )}
    </div>
  );
};