import { useEffect } from "react";
import PublicLayout from "../layout/PublicLayout";
import { TextDictionary } from "../utils/TextDictionary";

const Checkout = () => {
    useEffect(() => {
        console.log("Checkout mounted");
    }, []);

    return (
        <PublicLayout>
            <div>
                <h1 className="text-xl font-medium">{TextDictionary.Checkout.Titolo}</h1>
            </div>
        </PublicLayout>
    );
};

export default Checkout;