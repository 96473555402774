import axios from 'axios';
import { useEffect, useState } from 'react';
import Select from 'react-select';
import { ApiConfig } from '../config/Configuration';
import { TableComponent } from './TableComponent';
import { createGrid, GridApi, GridOptions } from 'ag-grid-community';
import { useNavigate } from 'react-router-dom';
import { TextDictionary } from '../utils/TextDictionary';

interface RelationHandlerComponentProps {
    options: Options;
}

interface Options {
    endpoint: string;
    element: { [key: string]: any };
    dictionaryContext?: string;
    columsToShow?: string[];
    dropdownElementAttributes?: string[];

    idsAttribute?: string;

    leftLabel?: string;
    rightLabel?: string;
    noDataMessage?: string;
}


interface CustomOptionProps {
    innerRef: any;
    innerProps: any;
    isSelected: boolean;
    data: any;
    selectOption: any;
}

export const RelationHandlerComponent = ({ options }: RelationHandlerComponentProps) => {
    const navigate = useNavigate();

    const [items, setItems] = useState<any[]>([]); // Array di oggetti con i dati ricevuti dalla chiamata API
    const [data, setData] = useState<any[]>([]); // Array di oggetti con value e label per le opzioni del select
    const [selectedOptions, setSelectedOptions] = useState<any[]>([]);
    const [gridApi, setGridApi] = useState<GridApi | null>(null);

    useEffect(() => {
        fetchData();
    }, [options.endpoint]);

    const fetchData = async () => {
        const response = await axios.get(`${ApiConfig.ROOT_URL}${options.endpoint}`);
        setItems(response.data); // Memorizza gli elementi ricevuti dalla chiamata API

        const data = response.data.map((item: any) => ({ // Map degli elementi per creare un array di oggetti con value e label
            value: item.ID,
            // faccio in modo che dentro a label ci siano concatenate le stringhe dentro a dropdownElementAttributes
            label: options.dropdownElementAttributes ? options.dropdownElementAttributes.map((attribute) => item[attribute]).join(' ') : item.Name
        }));

        setData(data);
    };

    useEffect(() => {
        // Prendo la prima chiave dell'oggetto element
        const key = Object.keys(options.element)[0];
        const el = options.element[key] // Prendo l'elemento associato alla chiave

        const selectedOptions = data.filter((item) => el[options.idsAttribute ?? ''].includes(item.value)); // Filtra gli elementi che hanno ID incluso in element.IDs
        setSelectedOptions(selectedOptions);
    }, [data]);

    useEffect(() => {
        if (!selectedOptions || selectedOptions.length === 0) {
            if (gridApi) {
                gridApi.updateGridOptions({ rowData: [] });
            }
            return;
        }
        createTable();
    }, [selectedOptions]);

    const createTable = () => {
        // Faccio in modo che se la tabella è già stata creata e gridApi è già stato inizializzato,
        // allora mi limito soltanto ad aggiornare i dati della tabella
        if (gridApi) {
            // Aggiorno i dati della tabella
            const rowData = selectedOptions.map((item: any) => items.find((i: any) => i.ID === item.value));
            gridApi.updateGridOptions({ rowData });
            return;
        }


        const gridColumnList: { headerName: string, field: string }[] = [];

        for (const key in items[0]) {
            const field = key;
            if (!gridColumnList.find((column) => column.field === field)
                && (!options.columsToShow || options.columsToShow.includes(field))) {
                // Includo la colonna solo se il parametro columsToShow non è stato passato oppure se è stato passato e la colonna è inclusa


                let dictionaryField;
                Object.keys(TextDictionary.Admin.Tabelle).forEach((key) => {
                    if (key === options.dictionaryContext) {
                        dictionaryField = key;
                    }
                });

                gridColumnList.push({
                    headerName: dictionaryField ? TextDictionary.Admin.Tabelle[dictionaryField][field] : field,
                    field: field
                });
            }
        }

        // Aggiungo la colonna delle azioni
        gridColumnList.push({
            headerName: 'Azioni',
            field: 'ID'
        });

        const columnDefs = gridColumnList.map((column) => {
            if (column.headerName === 'Azioni') {
                return {
                    headerName: '',
                    field: column.field,
                    minWidth: 150,
                    filter: false,
                    sortable: false,
                    cellRenderer: (params: any) => {
                        const fieldValue = params.value;
                        // faccio in modo che il value della riga sia l'ID dell'elemento, in modo da poterlo passare alla funzione di modifica o cancellazione
                        const handleRemove = (fieldValue: any) => {
                            // navigate('/admin' + endpoint + '/' + fieldValue);

                            // Tolgo l'elemento dalla lista degli elementi selezionati
                            const newSelectedOptions = selectedOptions.filter((item) => item.value !== fieldValue);
                            setSelectedOptions(newSelectedOptions);
                        };

                        const container = document.createElement('div');
                        container.className = 'flex items-center justify-end gap-2 w-full mt-1';

                        const removeButton = document.createElement('button');
                        removeButton.className = 'bg-red-600 text-white text-sm px-6 py-1 rounded-full hover:bg-transparent hover:text-red-600 border border-red-600';
                        removeButton.innerText = 'Elimina';
                        // come secondo parametro passo props.endpoint per poter costruire il link
                        removeButton.addEventListener('click', () => handleRemove(fieldValue));

                        container.appendChild(removeButton);

                        return container;
                    }
                };
            }

            return column;
        });


        const rowData = selectedOptions.map((item: any) => items.find((i: any) => i.ID === item.value));

        const gridOptions = {
            columnDefs,
            rowData,
            defaultColDef: {
                sortable: true,
                filter: true,
                resizable: true,
            },
            autoSizeStrategy: {
                type: "fitGridWidth",
            },
            overlayNoRowsTemplate: options.noDataMessage, // Messaggio da mostrare se non ci sono dati
        } as GridOptions;

        gridOptions.pagination = true;
        gridOptions.paginationPageSize = 10;
        gridOptions.paginationPageSizeSelector = [10];

        const myGrid = document.getElementById('resultsTable') as HTMLElement;
        if (myGrid) {
            myGrid.innerHTML = '';
        }

        const grid = createGrid(myGrid, gridOptions);
        setGridApi(grid);
    }

    const handleChange = (selected: any) => {
        setSelectedOptions(selected);  // Memorizza gli elementi selezionati
    };

    const CustomOption = ({ innerRef, innerProps, isSelected, data, selectOption }: CustomOptionProps) => {
        // Aggiungi una funzione per gestire il click sulla checkbox
        const handleCheckboxChange = () => {
            selectOption(data); // Chiamata per selezionare/deselezionare l'opzione
        };

        return (
            <div ref={innerRef} {...innerProps} style={{ display: 'flex', alignItems: 'center', padding: '5px 15px', cursor: 'pointer' }}>
                <input
                    type="checkbox"
                    id={data.value}
                    checked={isSelected}
                    onChange={handleCheckboxChange}  // Gestisce la selezione
                    style={{ marginRight: 15 }}
                />
                <label
                    htmlFor={data.value}
                >{data.label}</label>
            </div>
        );
    };

    return (
        <div className="flex flex-row w-full gap-4 items-start h-96 pb-8">
            <div className="w-1/2 pr-10 h-full">
                <span className="text-md font-semibold mb-2">{options.leftLabel}</span>
                {
                    selectedOptions.length === 0 && !gridApi ? (
                        <p className='mt-4'>{options.noDataMessage}</p>
                    ) : null
                }

                <div id="resultsTable" className="ag-theme-quartz h-full w-full mt-4" />

            </div>
            <div className="w-1/2 h-full">
                <span className="text-md font-semibold mb-2">{options.rightLabel}</span>
                <Select
                    className='mt-4'
                    options={data}
                    value={selectedOptions.map((option: any) => data.find((o: any) => o.value === option.value))}  // Mostra le opzioni selezionate
                    controlShouldRenderValue={false}  // Nasconde il valore selezionato
                    isMulti
                    isSearchable
                    components={{
                        DropdownIndicator: () => null,
                        IndicatorSeparator: () => null,
                        ClearIndicator: () => null,
                        Option: (props) => <CustomOption {...props} selectOption={props.selectOption} />
                    }}
                    onChange={handleChange}
                    closeMenuOnSelect={false}  // Mantiene il menu aperto dopo la selezione
                    hideSelectedOptions={false}  // Mantiene le opzioni selezionate visibili nella lista
                    placeholder="Cerca utente..."
                />
            </div>
        </div>
    );
}