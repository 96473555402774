import { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";
import DropdownUser from "./DropdownUser";
import { AppConfig } from "../config/Configuration";

interface HeaderProps {
  isUserSetted: boolean;
  isAdminArea: boolean;
}

const Header = ({ isUserSetted, isAdminArea }: HeaderProps) => {
  const navigate = useNavigate();

  const [coursesDropdownOpen, setCoursesDropdownOpen] = useState(false);

  // gestisco il click outside per chiudere le dropdown
  useEffect(() => {
    const handleClickOutside = (event: any) => {
      if (coursesDropdownOpen) {
        if (event.target.closest('.coursesDropdown') === null) {
          setCoursesDropdownOpen(false);
        }
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, [coursesDropdownOpen]);

  return (
    <header className="sticky top-0 flex w-full bg-primary border-0 justify-center" style={{ zIndex: 999 }}>
      <div className={`h-[100px] flex flex-grow items-center justify-between px-4 py-4 md:px-6 2xl:px-11 ${!isAdminArea && 'max-w-[1400px]'}`}>
        <div className="flex items-center gap-10 cursor-pointer">
          <div className="text-white font-medium text-lg cursor-pointer" onClick={
            () => {
              navigate('/');
            }
          }>
            <img src={AppConfig.brandLogo} alt="logo" className="h-16" />
            {/* <AppConfig.brandLogo className="h-16"/> */}
          </div>
          {
            !isAdminArea ? (
              <>
                <div className="relative">
                  <div className="text-white text-md cursor-pointer" onClick={
                    () => {
                      setCoursesDropdownOpen(!coursesDropdownOpen);
                    }
                  }>
                    Corsi
                  </div>
                  <div className={`coursesDropdown absolute z-10 w-60 overflow-hidden bg-white rounded-md shadow-xl top-[50px]  ${coursesDropdownOpen ? 'block' : 'hidden'}`}>
                    <span className="block px-4 py-2 text-[10px] text-black font-medium">
                      CATEGORIE
                    </span>
                    <span className="block px-4 py-2 text-sm text-black transition-colors duration-200 transform hover:bg-[#e2e2e2]" onClick={() => {
                      console.log('Cliccato sulla categoria Dogana');
                    }}>
                      Dogana
                    </span>
                    <span className="block px-4 py-2 text-sm text-black transition-colors duration-200 transform hover:bg-[#e2e2e2]" onClick={() => {
                      console.log('Cliccato sulla categoria Import / Export');
                    }}>
                      Import / Export
                    </span>
                    <span className="block px-4 py-2 text-sm text-black transition-colors duration-200 transform hover:bg-[#e2e2e2]" onClick={() => {
                      console.log('Cliccato sulla categoria Trasporti');
                    }}>
                      Trasporti
                    </span>
                    <span className="block px-4 py-2 text-sm text-black transition-colors duration-200 transform hover:bg-[#e2e2e2] border-t border-t-[#e2e2e2]" onClick={() => {
                      navigate('/courses-list');
                    }}>
                      Vedi tutti i corsi
                    </span>
                  </div>
                </div>
                <div className="text-white text-md cursor-pointer" onClick={
                  () => {
                    navigate('/subscription-info');
                  }
                }>
                  Scopri gli abbonamenti
                </div>
                <div className="text-white text-md cursor-pointer" onClick={
                  () => {
                    window.open('https://carbognani.srl/international-news', '_blank');
                  }
                }>
                  International News
                </div>
              </>
            ) : (
              null
            )
          }
        </div>

        {/* Cerca corsi */}

        {
          !isAdminArea ? (
            <>
            <input type="text" placeholder="Cerca corsi" className="px-4 py-1 w-[25rem] rounded-full bg-white text-primary outline-none" onKeyUp={
              (e: any) => {
                if (e.key === 'Enter') {
                  e.preventDefault();
                  // Porto alla pagina /courses-list con i parametri di ricerca
                  let text = e.target.value;
                  navigate(`/courses-list?search=${text}`);
                }
              }
            } />
            <div className="flex items-center gap-8">
              {/* Carrello */}
              <span className="text-white text-xm cursor-pointer" onClick={
                () => {
                  navigate('/cart');
                }
              }>
                Carrello
              </span>
              <DropdownUser />
            </div>
            </>
          ) : (
            null
          )
        }
      </div>
    </header>
  );
};

export default Header;
