import { useCallback, useEffect, useState } from "react";
import AdminLayout from "../../../layout/AdminLayout";
import { Link, useLoaderData, useParams } from "react-router-dom";
import Select from 'react-select';
import { RelationHandlerComponent } from "../../../components/RelationHandlerComponent";
import { TextDictionary } from "../../../utils/TextDictionary";
import { PrimaryButton } from "../../../components/PrimaryButton";
import { FloatingBottomToolbar } from "../../../components/FloatingBottomToolbar";
import CustomDropzone from "../../../components/CustomDropzone";


interface CustomOptionProps {
    innerRef: any;
    innerProps: any;
    isSelected: boolean;
    data: any;
    selectOption: any;
}

const SingleQuiz = () => {
    const { id } = useParams();

    const quiz = useLoaderData() as any | null;

    const options = {
        videos: {
            endpoint: "/videos",
            element: { quiz },
            dictionaryContext: "Video",
            columsToShow: ["Title", "Description", "URLVideo"],
            dropdownElementAttributes: ["Title"],
            idsAttribute: "VideoIDs",
            leftLabel: TextDictionary.Admin.Quiz.VideoAssociati,
            rightLabel: TextDictionary.Admin.Quiz.AggiungiVideo,
            noDataMessage: TextDictionary.Admin.Quiz.NessunVideo
        }
    }

    const onDrop = useCallback((acceptedFiles: any) => {
        acceptedFiles.map((file: any) => {
            const reader = new FileReader();

            reader.onload = function (e) {
                const data = e.target?.result;
                console.log("----------- FILE SELEZIONATO", data);
            };

            reader.readAsDataURL(file);
            return file;
        });
    }, []);

    return (
        <AdminLayout>
            <div className="flex flex-row w-full gap-4">
                <div className="flex flex-col gap-4 flex-1 w-full">
                    <h1 className="text-2xl font-semibold mb-8">{id ? TextDictionary.Admin.Quiz.ModificaQuiz : TextDictionary.Admin.Quiz.NuovoQuiz}</h1>

                    {/* Nome e importo */}
                    <div className="flex flex-col items-start gap-4 w-full">
                        <div className="flex flex-col mb-4 w-full">
                            <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Quiz.CampoNome}</label>
                            <div className="flex flex-col gap-4">
                                <input type="text" id="name" className="border border-black border-opacity-10 w-96 px-2 py-1" defaultValue={id ? quiz.Title : ''} />
                            </div>
                        </div>
                    </div>

                    {/* Descrizione */}
                    <div className="flex flex-col mb-4 w-full">
                        <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Quiz.CampoDescrizione}</label>
                        <textarea className="border border-black border-opacity-10 w-96 h-32 resize-none px-2" defaultValue={id ? quiz.Description : ''} />
                    </div>


                    {/* File da caricare */}
                    <div className="flex flex-col mb-4 w-full">
                        <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Quiz.CampoFile}</label>
                        {/* <Dropzone onDrop={acceptedFiles => console.log(acceptedFiles)}>
                            {({ getRootProps, getInputProps }) => (
                                <section>
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <p>Drag 'n' drop some files here, or click to select files</p>
                                    </div>
                                </section>
                            )}
                        </Dropzone> */}

                        <CustomDropzone 
                            onDrop={onDrop}
                            // Faccio in modo che venga accettato solo il csv
                            accept=".csv"
                        />
                    </div>


                    {/* Punteggio minimo */}
                    <div className="flex flex-row mb-4 w-full items-center gap-4">
                        <label htmlFor="name" className="font-semibold text-md">{TextDictionary.Admin.Quiz.CampoPunteggioMinimo}</label>
                        <input type="number" id="name" className="border border-black border-opacity-10 w-32 px-2 py-1" defaultValue={id ? quiz.MinScore : ''} />
                        / <span className="text-sm">{TextDictionary.Admin.Quiz.PlaceholderPunteggioMassimo}</span>
                    </div>


                    {/* Associazioni da fare:
                        - Corso
                        - Video
                    */}

                    <RelationHandlerComponent
                        options={options.videos}
                    />
                </div>

                {/* Card di salvataggio */}
                <FloatingBottomToolbar onSave={() => {
                    console.log('Salva');
                }} onDiscard={() => {
                    console.log('Annulla');
                }} />
            </div>
        </AdminLayout>
    );
};

export default SingleQuiz;