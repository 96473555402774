import { useEffect, useState } from "react";
import AdminLayout from "../../../layout/AdminLayout";
import { Link, useLoaderData, useParams } from "react-router-dom";
import Select from 'react-select';
import { RelationHandlerComponent } from "../../../components/RelationHandlerComponent";
import { TextDictionary } from "../../../utils/TextDictionary";
import { PrimaryButton } from "../../../components/PrimaryButton";
import { FloatingBottomToolbar } from "../../../components/FloatingBottomToolbar";
import NewDataRelationHandlerComponent from "../../../components/NewDataRelationHandlerComponent";


interface CustomOptionProps {
    innerRef: any;
    innerProps: any;
    isSelected: boolean;
    data: any;
    selectOption: any;
}

const SingleCoupon = () => {
    const { id } = useParams();

    const coupon = useLoaderData() as any | null;

    // Da sostituire con l'enum CouponType
    const [couponType, setCouponType] = useState<'percentuale' | 'fisso'>('percentuale'); // percentuale | fisso

    const options = {
        users: {
            endpoint: "/users",
            element: {coupon},
            dictionaryContext: "Utenti",
            columsToShow: ["Name", "Surname", "Email"],
            dropdownElementAttributes: ["Name", "Surname"],
            idsAttribute: "UserIDs",
            leftLabel: TextDictionary.Admin.Coupons.UtentiAssociati,
            rightLabel: TextDictionary.Admin.Coupons.AggiungiUtenti,
            noDataMessage: TextDictionary.Admin.Coupons.NessunDato
        },
        newUsers: {
            leftLabel: TextDictionary.Admin.Coupons.UtentiAssociati,
            rightLabel: TextDictionary.Admin.Coupons.AggiungiUtenti,
            noDataMessage: TextDictionary.Admin.Coupons.NessunNuovoUtente,
            columnName: "Email"
        }
    }

    useEffect(() => {
        console.log("SingleCoupon mounted");
        console.log("coupon", coupon);
    }, []);

    const genCasualCode = () => {
        console.log("Genera codice casuale");

        // Caratteri alfanumerici
        // se generato con tasto max 8 caratteri
        // tutti trasformati in maiuscolo

        const randomString = Math.random().toString(36).substring(2, 10).toUpperCase();
        console.log(randomString);

        // Setto il valore del campo name con il codice generato
        const nameInput = document.getElementById('name') as HTMLInputElement;
        nameInput.value = randomString;
    }

    return (
        <AdminLayout>
            <div className="flex flex-row w-full gap-4">
                <div className="flex flex-col gap-4 flex-1 w-full">
                    <h1 className="text-2xl font-semibold mb-8">{id ? TextDictionary.Admin.Coupons.ModificaCoupon : TextDictionary.Admin.Coupons.NuovoCoupon}</h1>
                    
                    {/* Nome e importo */}
                    <div className="flex flex-row items-start gap-4 w-full">
                        <div className="flex flex-col mb-4 w-full">
                            <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Coupons.CampoNome}</label>
                            <div className="flex flex-col gap-4">
                                <input type="text" id="name" className="border border-black border-opacity-10 w-96 px-2 py-1" defaultValue={id ? coupon.Title : ''} />
                                <a className="text-black underline cursor-pointer" onClick={genCasualCode}>{TextDictionary.Admin.Coupons.BottoneGenera}</a>
                            </div>
                        </div>

                        {/* Importo */}
                        <div className="flex flex-col mb-4 w-full gap-4">
                            <label htmlFor="name" className="font-semibold text-md">{TextDictionary.Admin.Coupons.CampoImporto}</label>
                            <div className="flex flex-row gap-4 items-center">
                                <select name="type" id="type" className="bg-white px-2 py-1 border border-black border-opacity-10 w-56" onChange={(e) => {
                                    console.log('Tipo di importo', e.target.value);
                                    setCouponType(e.target.value as 'percentuale' | 'fisso');
                                }}>
                                    <option value={'percentuale'} selected>{TextDictionary.Admin.Coupons.TipologiaPercentuale}</option>
                                    <option value={'fisso'}>{TextDictionary.Admin.Coupons.TipologiaFisso}</option>
                                </select>
                                <input type="number" id="value" className="border px-2 py-1 border-black border-opacity-10 w-20" defaultValue={id ? coupon.Value : ''} />
                                <span>{couponType === 'percentuale' ? '%' : '€'}</span>
                            </div>
                        </div>
                    </div>

                    {/* Descrizione */}
                    <div className="flex flex-col mb-4 w-full">
                        <label htmlFor="name" className="font-semibold text-md pb-4">{TextDictionary.Admin.Coupons.CampoDescrizione}</label>
                        <textarea className="border border-black border-opacity-10 w-96 h-32 resize-none px-2" defaultValue={id ? coupon.Description : ''} />
                    </div>


                    <RelationHandlerComponent
                        options={options.users}
                    />

                    {/* Creo un divisore */}
                    <div className="w-full border-b border-black border-opacity-10 my-8" />
                
                    <NewDataRelationHandlerComponent 
                        options={options.newUsers}
                    />

                </div>

                {/* Card di salvataggio */}
                <FloatingBottomToolbar onSave={() => {
                    console.log('Salva');
                }} onDiscard={() => {
                    console.log('Annulla');
                }} />
            </div>
        </AdminLayout>
    );
};

export default SingleCoupon;