import { useDropzone } from "react-dropzone";
import { TextDictionary } from "../utils/TextDictionary";

function CustomDropzone({onDrop, accept}) {


  const { getRootProps, getInputProps, acceptedFiles } =
    useDropzone({
      maxFiles: 1,
      onDrop: (acceptedFiles) => {
        onDrop(acceptedFiles);
      },
      accept: accept
    });

  const files = acceptedFiles.map((file) => (
    <li key={file.path}>
      <span>{file.path}</span>
    </li>
  ));

  return (
    <div className="container border border-dotted border-black w-96 px-4 py-4 cursor-pointer">
      <div {...getRootProps({ className: "dropzone" })}>
        <input {...getInputProps()} />
        <img src="https://img.icons8.com/ios/452/upload-to-cloud.png" alt="upload" className="mx-auto w-10 h-10 mb-4" />
        <p className="text-center text-sm">
          {TextDictionary.Admin.Dropzone.Testo}  
        </p>
      </div>
      <aside className="mt-4 text-center">
        <ul>
          {files}
        </ul>
      </aside>
    </div>
  );
}

export default CustomDropzone;