import { useEffect, useState } from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";

export const AdminSidebar = () => {
    const navigate = useNavigate();

    const [isCollapsed, setIsCollapsed] = useState(false);

    // Accedo a tutte le route contenenti /admin e senza parametri
    useEffect(() => {
        console.log("AdminSidebar mounted");
        console.log('navigate', navigate);
    }, []);


    return (
        <aside className={`flex h-full flex-col overflow-y-hidden bg-black ${isCollapsed ? "w-auto" : "w-40"
            } transition-all duration-300 ease-in-out no-scrollbar ${isCollapsed ? "overflow-x-hidden" : "overflow-y-auto"
            }`}>
            <div className="no-scrollbar flex flex-col overflow-y-hidden h-full">
                <nav className={`py-4 px-2 h-full ${isCollapsed ? "justify-center" : "justify-end"
                    }`}>
                    <div className="mb-6 flex flex-col gap-1.5 h-full">
                        <button className={`flex items-center text-white mb-4 ${isCollapsed ? "justify-center" : "justify-end"
                            }`} onClick={() => setIsCollapsed(!isCollapsed)}>
                            <svg width="20px" height="20px" viewBox="0 0 28 28" fill="white" xmlns="http://www.w3.org/2000/svg"><path d="M4 7C4 6.44771 4.44772 6 5 6H24C24.5523 6 25 6.44771 25 7C25 7.55229 24.5523 8 24 8H5C4.44772 8 4 7.55229 4 7Z" fill="white" /><path d="M4 13.9998C4 13.4475 4.44772 12.9997 5 12.9997L16 13C16.5523 13 17 13.4477 17 14C17 14.5523 16.5523 15 16 15L5 14.9998C4.44772 14.9998 4 14.552 4 13.9998Z" fill="white" /><path d="M5 19.9998C4.44772 19.9998 4 20.4475 4 20.9998C4 21.552 4.44772 21.9997 5 21.9997H22C22.5523 21.9997 23 21.552 23 20.9998C23 20.4475 22.5523 19.9998 22 19.9998H5Z" fill="white" /></svg>
                        </button>
                        <div className="flex flex-col flex-1 h-full">
                            <NavLink
                                to="/admin/dashboard"
                                className={`relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-white`}
                                style={({ isActive, isPending }) => {
                                    return {
                                        textDecoration: isActive || isPending ? "underline" : "none",
                                    };
                                }}
                            >
                                {
                                    isCollapsed ? (
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" fill="transparent" xmlns="http://www.w3.org/2000/svg">
                                            <rect width="20px" height="20px" fill="transparent" />
                                            <path d="M15.024 22C16.2771 22 17.3524 21.9342 18.2508 21.7345C19.1607 21.5323 19.9494 21.1798 20.5646 20.5646C21.1798 19.9494 21.5323 19.1607 21.7345 18.2508C21.9342 17.3524 22 16.2771 22 15.024V12C22 10.8954 21.1046 10 20 10H12C10.8954 10 10 10.8954 10 12V20C10 21.1046 10.8954 22 12 22H15.024Z" fill="white" />
                                            <path d="M2 15.024C2 16.2771 2.06584 17.3524 2.26552 18.2508C2.46772 19.1607 2.82021 19.9494 3.43543 20.5646C4.05065 21.1798 4.83933 21.5323 5.74915 21.7345C5.83628 21.7538 5.92385 21.772 6.01178 21.789C7.09629 21.9985 8 21.0806 8 19.976L8 12C8 10.8954 7.10457 10 6 10H4C2.89543 10 2 10.8954 2 12V15.024Z" fill="white" />
                                            <path d="M8.97597 2C7.72284 2 6.64759 2.06584 5.74912 2.26552C4.8393 2.46772 4.05062 2.82021 3.4354 3.43543C2.82018 4.05065 2.46769 4.83933 2.26549 5.74915C2.24889 5.82386 2.23327 5.89881 2.2186 5.97398C2.00422 7.07267 2.9389 8 4.0583 8H19.976C21.0806 8 21.9985 7.09629 21.789 6.01178C21.772 5.92385 21.7538 5.83628 21.7345 5.74915C21.5322 4.83933 21.1798 4.05065 20.5645 3.43543C19.9493 2.82021 19.1606 2.46772 18.2508 2.26552C17.3523 2.06584 16.2771 2 15.024 2H8.97597Z" fill="white" />
                                        </svg>
                                    ) : "Dashboard"

                                }

                            </NavLink>

                            <NavLink
                                to="/admin/users"
                                className={`relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-white`}
                                style={({ isActive, isPending }) => {
                                    return {
                                        textDecoration: isActive || isPending ? "underline" : "none",
                                    };
                                }}
                            >
                                {
                                    isCollapsed ? (
                                        <svg width="20px" height="20px" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M8 7C9.65685 7 11 5.65685 11 4C11 2.34315 9.65685 1 8 1C6.34315 1 5 2.34315 5 4C5 5.65685 6.34315 7 8 7Z" fill="white" />
                                            <path d="M14 12C14 10.3431 12.6569 9 11 9H5C3.34315 9 2 10.3431 2 12V15H14V12Z" fill="white" />
                                        </svg>
                                    ) : "Utenti"

                                }

                            </NavLink>

                            <NavLink
                                to="/admin/courses"
                                className={`relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-white`}
                                style={({ isActive, isPending }) => {
                                    return {
                                        textDecoration: isActive || isPending ? "underline" : "none",
                                    };
                                }}
                            >
                                {
                                    isCollapsed ? (
                                        <svg width="20px" height="20px" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
                                        <path fill="none" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M1 2h16v11H1z"/>
                                        <path fill="none" stroke="white" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M4 5.5v5s3-1 5 0v-5s-2-2-5 0zM9 5.5v5s3-1 5 0v-5s-2-2-5 0z"/>
                                        <path fill="white" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" stroke-miterlimit="10" d="M8.5 14l-3 3h7l-3-3z"/>
                                      </svg>
                                    ) : "Corsi"

                                }

                            </NavLink>

                            <NavLink
                                to="/admin/quizzes"
                                className={`relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-white`}
                                style={({ isActive, isPending }) => {
                                    return {
                                        textDecoration: isActive || isPending ? "underline" : "none",
                                    };
                                }}
                            >
                                {
                                    isCollapsed ? (
                                        <svg width="20px" height="20px" viewBox="0 0 1920 1920" xmlns="http://www.w3.org/2000/svg" fill="white">
                                            <g fill-rule="evenodd" clip-rule="evenodd" stroke="none" stroke-width="1">
                                                <path d="M1468.235 113v99.388l-112.94 112.941v-99.388H112.94v1468.235h1242.353v-438.211l112.941-112.941v664.094H0V113h1468.235ZM421.271 1163.353c96-9.035 154.729 108.423 190.87 197.647 28.235 68.894 38.4 92.612 72.283 96 33.882 3.388 89.223-58.73 112.94-101.647 16.53-26.51 51.42-34.6 77.93-18.07 26.51 16.529 34.6 51.42 18.07 77.929-9.035 16.94-92.611 160.376-205.552 160.376h-9.036c-70.023-4.517-121.976-48.564-169.411-166.023-47.436-117.46-77.93-127.624-77.93-127.624a484.518 484.518 0 0 0-97.13 225.883c-6.549 31.187-37.14 51.16-68.329 44.611-31.187-6.55-51.16-37.141-44.611-68.33 20.33-94.87 79.059-310.587 199.906-320.752Zm256.376-485.647v112.941H338.824V677.706h338.823ZM903.53 451.824v112.94H338.824v-112.94h564.705Z" />
                                                <path d="m1903.059 468.765-225.883-225.883a56.47 56.47 0 0 0-80.188 0L919.341 920.53a56.476 56.476 0 0 0-15.813 39.53v282.353h282.354a56.47 56.47 0 0 0 39.53-16.941l677.647-677.647c21.523-21.959 21.523-57.101 0-79.06Zm-740.894 660.706H1016.47V983.776l451.764-451.764 145.694 145.694-451.764 451.765Zm531.953-531.953-145.694-145.694 89.223-89.224 145.694 145.694-89.223 89.224Z" />
                                            </g>
                                        </svg>
                                    ) : "Quiz"

                                }

                            </NavLink>

                            <NavLink
                                to="/admin/resources"
                                className={`relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-white`}
                                style={({ isActive, isPending }) => {
                                    return {
                                        textDecoration: isActive || isPending ? "underline" : "none",
                                    };
                                }}
                            >
                                {
                                    isCollapsed ? (
                                        <svg fill="white" width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M19,2H5A1,1,0,0,0,4,3V21a1,1,0,0,0,1,1H19a1,1,0,0,0,1-1V3A1,1,0,0,0,19,2ZM14.577,12.416l-4.6,3.066a.5.5,0,0,1-.777-.416V8.934a.5.5,0,0,1,.777-.416l4.6,3.066A.5.5,0,0,1,14.577,12.416Z" /></svg>
                                    ) : "Risorse"

                                }

                            </NavLink>

                            <NavLink
                                to="/admin/coupons"
                                className={`relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-white`}
                                style={({ isActive, isPending }) => {
                                    return {
                                        textDecoration: isActive || isPending ? "underline" : "none",
                                    };
                                }}
                            >
                                {
                                    isCollapsed ? (
                                        <svg width="20px" height="20px" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" id="coupon" fill="white"><path d="M20,4H14V6.5a1,1,0,0,1-2,0V4H4A2,2,0,0,0,2,6V9a1,1,0,0,0,1,1,2,2,0,0,1,0,4,1,1,0,0,0-1,1v3a2,2,0,0,0,2,2h8V17.5a1,1,0,0,1,2,0V20h6a2,2,0,0,0,2-2V6A2,2,0,0,0,20,4Zm-6,9.83a1,1,0,0,1-2,0V10.17a1,1,0,0,1,2,0Z"></path></svg>
                                    ) : "Coupon"

                                }

                            </NavLink>
                        </div>

                        {/* Divisore */}
                        <div className="flex flex-col">
                            <div className="border-t border-white border-opacity-20 mb-2"></div>
                            <div className="w-full flex flex-col justify-between">
                                <Link
                                    to="/"
                                    className={`relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-white`}
                                >
                                    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="20" height="20" viewBox="0 0 48 48" fill="white">
                                        <path d="M39.5,43h-9c-1.381,0-2.5-1.119-2.5-2.5v-9c0-1.105-0.895-2-2-2h-4c-1.105,0-2,0.895-2,2v9c0,1.381-1.119,2.5-2.5,2.5h-9	C7.119,43,6,41.881,6,40.5V21.413c0-2.299,1.054-4.471,2.859-5.893L23.071,4.321c0.545-0.428,1.313-0.428,1.857,0L39.142,15.52	C40.947,16.942,42,19.113,42,21.411V40.5C42,41.881,40.881,43,39.5,43z"></path>
                                    </svg>
                                    {
                                        isCollapsed ? "" : "Home"
                                    }
                                </Link>
                                <Link to="/" className="relative flex items-center gap-2.5 rounded-sm py-2 px-4 font-medium text-white">
                                    <svg fill="white" height="15" width="15" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink"
                                        viewBox="0 0 384.971 384.971" xmlSpace="preserve">
                                        <g>
                                            <g id="Sign_Out">
                                                <path d="M180.455,360.91H24.061V24.061h156.394c6.641,0,12.03-5.39,12.03-12.03s-5.39-12.03-12.03-12.03H12.03
                                                        C5.39,0.001,0,5.39,0,12.031V372.94c0,6.641,5.39,12.03,12.03,12.03h168.424c6.641,0,12.03-5.39,12.03-12.03
                                                        C192.485,366.299,187.095,360.91,180.455,360.91z"/>
                                                <path d="M381.481,184.088l-83.009-84.2c-4.704-4.752-12.319-4.74-17.011,0c-4.704,4.74-4.704,12.439,0,17.179l62.558,63.46H96.279
                                                        c-6.641,0-12.03,5.438-12.03,12.151c0,6.713,5.39,12.151,12.03,12.151h247.74l-62.558,63.46c-4.704,4.752-4.704,12.439,0,17.179
                                                        c4.704,4.752,12.319,4.752,17.011,0l82.997-84.2C386.113,196.588,386.161,188.756,381.481,184.088z"/>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                            <g>
                                            </g>
                                        </g>
                                    </svg>
                                    {
                                        isCollapsed ? "" : "Logout"
                                    }
                                </Link>
                            </div>
                        </div>
                    </div>
                </nav>
            </div>
        </aside>
    );
};